<template>
    <el-container  class="jumpSpace">
        <el-header>
            <div class="page_title1">
                找项目：科技企业集聚追踪系统（精准招商）
            </div>
            <div class="page_title2">
                电子政策汇编系统-{{policy_proName?policy_proName:''}}落地园区
            </div>
        </el-header>
        <el-main>
            <el-button  class="go_back" type="text" @click="goBack"> <i class="el-icon-arrow-left"></i>返回</el-button>
            <el-table border :data="tableData" style="width: 100%;">
                <el-table-column label="空间名称" align="center" prop='name' min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="空间类型" align="center"  min-width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="margin:3px;" v-for="(item,index) in scope.row.park_type" :key="index">{{item}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="空间面积" align="center" prop='measure' min-width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.measure}}㎡</span>
                    </template>
                </el-table-column>
                <el-table-column label="地理位置" align="center" prop='title' min-width="140" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.province}}{{scope.row.city}}{{scope.row.district}}{{scope.row.town}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="常用手机" align="center" prop='mobile' min-width="140" show-overflow-tooltip></el-table-column>
                <el-table-column label="电话" align="center" prop='tel' min-width="140" show-overflow-tooltip></el-table-column>
                <el-table-column label="状态" align="center" prop='rent_state' min-width="100">
                </el-table-column>
                <el-table-column label="匹配度" align="center"  min-width="140" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="color: #409EFF;">{{scope.row.weight?scope.row.weight:0}}%</span>
                    </template>
                </el-table-column>

            </el-table>
            <Pages  @currentPageChange="pageChange"
                    :pageSize="limit"
                    :page="meta.current_page"
                    :total="meta.total"
                    v-if="tableData.length>0"
                    style="margin:20px auto;text-align: center;"
            />
        </el-main>
    </el-container>
</template>

<script>
    import Pages from "@/components/PageComponents"; //分页
    import {
        success,
        error,
        warning
    } from "@/utils/notification";
    export default {
        data(){
            return{
                meta:{},
                limit:15,
                page:1,
                tableData:[
                ],
                policy_proName:'',
                project_id:0,
            }
        },
        components:{
            Pages
        },
        created() {
            this.policy_proName = sessionStorage.policy_proName
            if(this.$route.query.id){
                this.project_id = this.$route.query.id
                // this.project_id = 10220
                // this.get_spacelist(10220)
                this.get_spacelist(this.$route.query.id)
            }
        },
        methods: {
            pageChange(val) {
                this.page = val
                this.get_spacelist(this.project_id)
            },
            goBack(){
                this.$router.go(-1)
                sessionStorage.backName = 'showPro'
            },
            get_spacelist(id){ // 获取政策 空间列表
                this.$http.get(this.URL.adminUrl.policy_spacelist+id+'/space',{params:{
                        limit:15,page:this.page
                    }})
                    .then(res=>{
                        // console.log(res,'kongjian')
                        this.tableData = res.data.data.space
                        this.meta = res.data.data.meta
                    })
                    .catch(err=>{
                        error('请求数据失败，请联系管理员')
                        console.log(err)
                    })
            }
        },


    }
</script>

<style scoped>
    .go_back {
        height: 40px !important;
    }
    .page_title1{
        text-align: center;
        font-size: 28px;
        font-width: bold;
        color:#333;
    }
    .page_title2{
        margin-top:20px;
        text-align: center;
        font-size: 24px;
        font-width: 500;
    }
    .el-tag {
        margin:3px;
    }
</style>